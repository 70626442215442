import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Row, Dropdown, Alert } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import moment from 'moment';
import { capitalize, cloneDeep, pick, last, orderBy, chunk, range, debounce } from 'lodash';
import { PDFDocument, AcroFieldFlags, ImageAlignment, PDFString, PDFName } from 'pdf-lib';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PQueue from 'p-queue';
import fontkit from '@pdf-lib/fontkit';
import montserratBoldFont from 'fonts/Montserrat-Bold.ttf';
import montserratSemiBoldFont from 'fonts/Montserrat-SemiBold.ttf';
import montserratRegularFont from 'fonts/Montserrat-Regular.ttf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Header from 'components/header';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import { alterSchema, getPrimaryKey } from 'utils/schema';
import iconType from 'utils/iconType';
import { formatNumber } from 'utils/number';
import { loadImage } from 'utils/image';
import { isClient as _isClient } from 'services/auth';
import * as Contact from 'views/contact/config';
import * as Client from 'views/client/config';
import * as CampaignItem from 'views/campaignItem/config';
import * as CampaignMarket from 'views/campaignMarket/config';
import * as CampaignPlanGoogleDoc from 'views/campaignPlanGoogleDoc/config';
import * as CampaignStage from 'views/campaignStage/config';
import * as CampaignStageFlow from 'views/campaignStageFlow/config';
import Venue from 'views/venue/index';
import * as Job from 'views/job/config';
import * as RouteSheet from 'views/routeSheet/config';
import * as Activity from 'views/activity/config';
import * as GoogleDoc from 'views/googleDoc/config';
import Photo from 'views/photo/index';
import { apiPath as photoApiPath } from 'views/photo/config';
import Attachment from 'views/attachment/index';
import { apiPath as venueApiPath, schema as venueSchema } from 'views/venue/config';
import * as CampaignReportPhoto from 'views/campaignReportPhoto/config';
import { model, schema } from './config';
import 'styles/detail.scss';
import 'styles/map.scss';
import 'styles/google.scss';
import 'styles/views/campaign.scss';
import 'styles/views/venue.scss';
import agreementPdf from './agreement.pdf';
import invoicePdf from './invoice.pdf';
import reportPdf from './report.pdf';
import reportCoverImage from './report-cover.png';
import reportMapMarker from './report-map-marker.svg';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);
ChartJS.defaults.font.family = 'Montserrat';

const PlanGoogleDocs = ({ data, docType }) => {
  const [ googleDocs, setGoogleDocs ] = useState(null);
  const [ planGoogleDoc, setPlanGoogleDoc ] = useState(null);
  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const googleDocsApiPath = `${GoogleDoc.apiPath(docType)}&campaignId=${data.id}`;
  const planGoogleDocKey = `googleDoc${docType.label}Id`;
  useEffect(async () => {
    if (docType === GoogleDoc.type.ATTACHMENT) {
      return;
    }
    const { data: planGoogleDoc } = (await axios.get(`${CampaignPlanGoogleDoc.apiPath}?campaignId=${data.id}`)) || {};
    if (planGoogleDoc) {
      const planGoogleDocId = planGoogleDoc[planGoogleDocKey];
      // const { googleDocRateId, googleDocDeckId } = planGoogleDoc;
      if (planGoogleDocId) {
        const { data: { items: docs } } = await axios.get(googleDocsApiPath);
        setGoogleDocs(docs);
        const doc = docs.find((doc) => doc.id === planGoogleDocId);
        if (doc) {
          setPlanGoogleDoc(doc);
        }
      }
    }
  }, []);

  const onSelectDoc = (doc) => {
    axios.post(CampaignPlanGoogleDoc.apiPath, { campaignId: data.id, [planGoogleDocKey]: doc.id });
    setPlanGoogleDoc(doc);
  };

  return (
    <div className="google-doc">
      {!planGoogleDoc ? (
          <Crud
            apiPath={googleDocsApiPath}
            schema={{ ...GoogleDoc.schema, ...{ name: { ...GoogleDoc.schema.name, label: 'Doc' } } }}
            schemaParent={schema}
            model={GoogleDoc.model}
            modelParent={model}
            label={GoogleDoc.label}
            nested
            dataParent={data}
            clientSide
            readOnly
            onRowClick={(node) => onSelectDoc(node.data)}
            onInit={({ rowData: { items } }) => {
              setGoogleDocs(items);
              forceUpdate();
            }}
          />
        ) :
        <>
          <Header
            label={false}
            icons={[
              {
                type: iconType.EXPORT,
                onClick: () => window.open(planGoogleDoc.webContentLink || planGoogleDoc.exportLinks?.[docType.exportLink]),
                tooltip: 'Export Doc',
              },
              {
                type: iconType.LINK_EXTERNAL,
                onClick: () => window.open(planGoogleDoc.webViewLink),
                tooltip: 'View Doc',
              },
            ]}
          />
          <Dropdown className="file">
            <Dropdown.Toggle variant="outline-secondary">Change</Dropdown.Toggle>
            <Dropdown.Menu>
              {googleDocs.map((doc, i) => (
                <React.Fragment key={i}>
                  <Dropdown.Item
                    className={classNames({ active: doc.id === planGoogleDoc.id })}
                    onClick={() => {
                      onSelectDoc(doc);
                    }}
                  >
                    {doc.name}
                  </Dropdown.Item>
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <iframe
            src={`${planGoogleDoc.webViewLink}&rm=minimal`}
            allowFullScreen
          />
        </>}
    </div>
  );
};

const Agreement = ({ data, schema, apiPath, campaignFormState }) => {
  const [ agreementFormState, setAgreementFormState ] = useState(null);
  const [ invoiceFormState, setInvoiceFormState ] = useState(null);
  const [ agreementSchema, setAgreementSchema ] = useState(null);
  const [ invoiceSchema, setInvoiceSchema ] = useState(null);

  useEffect(() => {
    setAgreementSchema(alterSchema(schema, { formFields: [ 'invoiceTotal', 'printingCost', 'commissionRate', 'commissionTotal', 'agreementText' ] }));
    setInvoiceSchema(alterSchema(schema, { formFields: [ 'date', 'invoiceNumber', 'invoicePaid', 'invoiceBalance', 'commissionPaid', 'invoiceText' ] }));
  }, []);
  if (!agreementSchema || !invoiceSchema) {
    return null;
  }

  agreementSchema.invoiceTotal.form.onSubmit = ({ updatedItem }) =>
    invoiceFormState.setFieldValue('invoiceBalance', updatedItem.invoiceBalance);

  const exportDoc = async (type) => {
    const campaignFormData = campaignFormState.values;
    const agreementFormData = agreementFormState.values;
    const invoiceFormData = invoiceFormState.values;

    let pdf;
    let fields;
    let formData;
    let fileLabel;
    if (type === 'agreement') {
      pdf = agreementPdf;
      fields = [ 'date', 'contact', 'agreementText' ];
      formData = agreementFormData;
      fileLabel = 'agmt';
    } else {
      pdf = invoicePdf;
      fields = [ 'date', 'invoiceNumber', 'contact', 'invoiceText', 'invoiceTotal', 'invoicePaid', 'invoiceBalance' ];
      formData = invoiceFormData;
      fileLabel = 'inv';
    }

    const [
      { data: billingContact },
      { data: primaryContact },
      { data: client },
    ] = await Promise.all([
      data.fk_billingContactId && axios.get(`${Contact.apiPath}/${data.fk_billingContactId}`),
      data.fk_primaryContactId && axios.get(`${Contact.apiPath}/${data.fk_primaryContactId}`),
      axios.get(`${Client.apiPath}/${data.fk_clientId}`),
    ]);
    const pdfDoc = await PDFDocument.load(await fetch(pdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();

    fields.forEach((key) => {
      const textField = pdfForm.getTextField(key);
      let value = formData[key];
      if (key === 'contact') {
        const contact = billingContact ? {
          ...billingContact,
          address: data.billingAddress,
          city: data.billingCity,
          state: data.billingState,
          zip: data.billingZip,
        } : {
          ...primaryContact,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
        };
        value = contact && `${contact.nameFirst} ${contact.nameLast}\n${client.name}\n${contact.address ? `${contact.address}\n` : ''}${contact.city ? `${contact.city}, ${contact.state} ${contact.zip}` : ''}`;
      } else if (key === 'date') {
        value = moment(invoiceFormData.date).format('MMMM D, YYYY');
      } else if (key === 'invoiceNumber') {
        value = `Invoice # ${value}`;
      }
      if (value) {
        textField.setText(schema[key]?.type === 'price' ? `$${formatNumber(value)}` : value);
        textField.acroField.setFlag(AcroFieldFlags.ReadOnly, true);
      }
    });
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    saveAs(pdfBlob, `${campaignFormData.title} ${fileLabel} ${moment().format('M-D-YYYY')}.pdf`);
  };

  agreementSchema.agreementText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: agreementFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
        // axios.get(`${Client.apiPath}/${campaignFormData.fk_clientId}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const placement =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? '• street-visible placement: WNDWposters™ hang in front of establishments, on doors, windows & in vestibules' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? '• high-visibility placement: Brochures displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? '• high-visibility placement: Postcards displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('magazine') ? '• high-visibility placement: Magazines displayed in front doorways & on front counters' : '';
      const timeframe =
        campaignFormData.flatRate ? '• Flat Rate, installed only [1-2 weeks on display]' :
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? `• ${weeksOnDisplay} weeks on display from full install date` :
        campaignFormData.drops ? `• ${campaignFormData.drops} drop(s): on display until all taken` : '';

      const size =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? 'WNDWposter™ Maximum Size:\n14x22 inches (308 sq inches), held vertically/portrait orientation.\nOversized, non-vertical/portrait oriented posters may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? 'Brochure Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, two folds maximum.\nOversized, overweight brochures may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? 'Postcard Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, no folds.\nOversized, overweight postcards may incur surcharge.' : '';

      const terms = '';
      /* const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : ''; */

      const text = `
${campaignFormData.title?.toUpperCase()}
${campaignFormData.type ?? ''}


• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s

• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}:
${campaignMarkets.map(({ neighborhoods }) => neighborhoods?.map(({ name }) => name)).flat().join(', ')} & adjacent areas if needed

${placement ? `${placement}\n` : ''}
• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts

• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

• full installation anticipated within ${campaignFormData.installDays} business days

${timeframe ? `${timeframe}\n\n\n` : ''}
E-REPORTS:
• name, type & address of every display venue
• pictures of ${campaignFormData.type}s on site
• interactive venue mapping by market [beta]



Print Production:

Display: ${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}

${size ? `${size}\n\n\n` : ''}


${terms}`;

      setFieldValue('agreementText', text.trim());

      /*
              Upper(D1_Campaign::CampaignTitle) & "¶" &
              Proper(D1_CampaignItemJoin::c_Type) & "¶¶¶" &


              "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " " & D1_CampaignItemJoin::c_Type & "s" & "¶¶" &
              "• targeting the neighborhoods of " & D1_Campaign::c_Market & ":" & "¶" &
              Substitute (List(D1_CampaignMarketJoin::FK_Neighborhood); "¶"; ", ") & " & adjacent areas if needed" & "¶¶" &


              Case (PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• street-visible placement:  Windowposters™ hang in front of establishments, on doors, windows & in vestibules";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "• high-visibility placement:  Brochures displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "• high-visibility placement: Postcards displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• in-store play:  Aerplay CDs are played for customers to hear";
              PatternCount(D1_CampaignItemJoin::c_Type; "magazine"); "• high-visibility placement:  Magazines displayed in front doorways & on front counters") & "¶¶" &

              "• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts" & "¶¶" &

              "• installation scheduled to begin the week of " & DayName ( D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

              "• full installation anticipated within " & D1_Campaign::InstallDays & " business days" & "¶¶" &

              Case (
                D1_Campaign::f_FlatRate = 1; "• Flat Rate, installed only [1-2 weeks on display]";
              PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• " & GetAsText (D1_Campaign::c_WeeksonDisplay) & " weeks on display from full install date";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• " & D1_Campaign::AerplayWeeks & "play week(s)";
              "• " & D1_Campaign::Drops & " drop(s): on display until all taken") & "¶¶¶¶" &

              "E-REPORTS: " & "¶" &
              "• name, type & address of every display venue" & "¶" &
              "• pictures of " & Proper(D1_CampaignItemJoin::c_Type) & "s on site" & "¶" &
              "• interactive venue mapping by market [beta]" & "¶¶¶¶" &

              "Print Production: " & "¶¶" &

              "Display: " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
              D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

              "Total Cost ... $" & D1_Campaign::InvoiceTotal & "¶¶" &

              TextSize(
                Case (
                  PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "Windowposter™ Maximum Size:" & "¶" & "14x22 inches (308 sq inches), held vertically/portrait orientation." & "¶" & "Oversized, non-vertical/portrait oriented posters may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "Brochure Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, two folds maximum." & "¶" & "Oversized, overweight brochures may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "Postcard Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, no folds." & "¶" & "Oversized, overweight postcards may incur surcharge." & "¶¶"
            ) &
              D1_Campaign::c_Terms; 7)
      */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('agreement'),
  }];

  invoiceSchema.invoiceText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: invoiceFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const agreementFormData = agreementFormState.values;
      const text = `
please make check payable to:
PMD Promotion
19 West 21st Street, Suite 901
New York, NY  10010






• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s
• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}
• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}
`;

      setFieldValue('invoiceText', text.trim());

      const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : '';
      setFieldValue('invoiceTerms', terms);

      /* "please make check payable to:¶PMD Promotion¶19 West 21st Street, Suite 901¶New York, NY  10010¶¶¶¶¶¶¶" &

      "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal) & " " & Upper(D1_Campaign::CampaignTitle) & " " & Proper(D1_CampaignItemJoin::c_Type) & "s" & "¶" &
      "• targeting the neighborhoods of " & D1_Campaign::c_Market & "¶" &
      "• installation scheduled to begin the week of " & DayName (D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

      Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
      D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

      "Total Cost ... $" & D1_Campaign::InvoiceTotal */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('invoice'),
  }];

  return (
    <div>
      <Row>
        <Col>
          <Form
            apiPath={apiPath}
            schema={agreementSchema}
            data={data}
            getState={(state) => setAgreementFormState(state)}
          />
        </Col>
        <Col>
          <Form
            apiPath={apiPath}
            schema={invoiceSchema}
            data={data}
            getState={(state) => setInvoiceFormState(state)}
          />
        </Col>
      </Row>
    </div>
  );
};

const Report = ({ detailData } = {}) => {
  const isClient = _isClient();
  const [ isReportSelectShowing, setIsReportSelectShowing ] = useState(false);
  const [ isReportSelectLoading, setIsReportSelectLoading ] = useState(true);
  const [ reportSelectJobs, setReportSelectJobs ] = useState([]);
  const [ reportSelectJobActive, setReportSelectJobActive ] = useState(null);
  const reportSelectPhotosRef = useRef(null);
  const [ reportSelectPhotos, setReportSelectPhotos ] = useState(null);
  const [ reportSelectPhotoData, setReportSelectPhotoData ] = useState({});
  const [ reportSelectPhotoModal, setReportSelectPhotoModal ] = useState(null);
  const reportSelectPhotoRef = useRef(null);
  const [ reportSelectPhotosActive, setReportSelectPhotosActive ] = useState([]);
  const reportSelectPhotosActiveRef = useRef(null);
  const [ reportSelectPhotoExportCounter, setReportSelectPhotoExportCounter ] = useState(null);
  const [ reportSelectChartData, setReportSelectChartData ] = useState(null);
  const [ reportSelectChartPhotoCountTotal, setReportSelectChartPhotoCountTotal ] = useState(null);
  const reportSelectChartRef = useRef(null);
  const reportSelectChartLegendRef = useRef(null);
  const [ reportNestedHeight, setReportNestedHeight ] = useState(null);
  const [ reportMap, setReportMap ] = useState(null);
  const reportMapRef = useRef(null); reportMapRef.current = reportMap;
  const [ reportMapMarkers, setReportMapMarkers ] = useState(null);
  const [ reportMapInfoWindow, setReportMapInfoWindow ] = useState(null);

  useEffect(async () => {
    const { data: { items: jobs } } = await axios.get(`${Job.apiPath}/?campaignId=${detailData.id}`);
    setReportSelectJobs(jobs);
    setReportSelectJobActive(jobs[0]);
  }, []);

  const queue = new PQueue({ concurrency: 10 });
  const fetchPhoto = async ({ photo, mounted = true }) => {
    const id = photo.id;
    if (photo.googleCloudUrl) {
      await queue.add(() => mounted && fetch(photo.googleCloudUrl, { cache: 'no-cache' })
        .then((response) => response.blob())
        .then((blob) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            photo.photo = reader.result.split(',')[1];
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })));
    } else {
      ({ data: photo } = await queue.add(() => mounted ? axios.get(`${photoApiPath}/${id}?fields=id,photo,date,time,flagApproved`) : {}));
    }

    if (mounted) {
      reportSelectPhotoData[id] = photo;
      setReportSelectPhotoData({
        ...reportSelectPhotoData,
      });
    }
    return photo;
  };

  const calcReportSelectPhotoCrop = (img) => {
    const { width, height } = img;
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        16 / 9,
        width,
        height,
      ),
      width,
      height,
    );
  };

  const cropPhoto = async ({ photo, crop, scale }) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = await loadImage(photo);
    crop = convertToPixelCrop({ ...crop, unit: '%' }, image.naturalWidth, image.naturalHeight);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;
    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );
    ctx.restore();
    return canvas.toDataURL('image/jpeg', 0.25).split(';base64,')[1];
  };

  useEffect(() => {
    let mounted = true;
    if (reportSelectJobActive) {
      (async () => {
        const venues = [];
        const { data: { items: photos } } = await axios.get(`${photoApiPath}/?campaignId=${detailData.id}&jobId=${reportSelectJobActive.id}`);
        const { data: { items: photosSaved } } = await axios.get(`${CampaignReportPhoto.apiPath}/?campaignId=${detailData.id}&jobId=${reportSelectJobActive.id}`);
        let photosActive = orderBy(photos.map((photo) => ({
          ...photo,
          position: photosSaved.find((photoSaved) => photoSaved.fk_photoId === photo.id)?.position,
        })).filter(({ position }) => typeof position !== 'undefined'), [ 'position', 'asc' ]);
        setReportSelectPhotosActive(photosActive);
        setIsReportSelectLoading(false);

        await Promise.all(photosActive.map(async (photo) => {
          const id = photo.id;
          photo = await fetchPhoto({ photo, mounted });
          const photoSaved = photosSaved.find((photoSaved) => photoSaved.fk_photoId === id);
          if (photoSaved) {
            photosActive = photosActive.filter((photoActive) => photoActive.id !== id);
            const photoActive = {
              ...photo,
              position: photoSaved.position,
              ...photoSaved.width && {
                crop: {
                  width: photoSaved.width,
                  height: photoSaved.height,
                  x: photoSaved.x,
                  y: photoSaved.y,
                },
                scale: photoSaved.scale,
              },
            };
            photosActive.push(photoActive);

            if (!isReportSelectShowing) {
              if (!photoActive.crop) {
                const image = await loadImage(photoActive.photo);
                photoActive.crop = calcReportSelectPhotoCrop(image);
                photoActive.scale = 1;
              }
              photoActive.crop.photo = await cropPhoto(photoActive);
            }

            setReportSelectPhotosActive(orderBy(photosActive, [ 'position', 'asc' ]));
          }
        })); // fetch active photos first
        photos.map(async (photo) => {
          const venue = photo.venue;
          if (!venues.some(({ id }) => venue.id === id)) {
            venues.push(venue);
            venue.photos = photos.filter((photo) => photo.venue.id === venue.id);
          }
        });
        setReportSelectPhotos(venues.reduce((acc, val) =>
          [ ...acc, ...val.photos.map((photo, i) => ({ ...photo, venuePhotoIndex: i })) ], []));

        const neighborhoods = venues.reduce((acc, venue) => {
          if (venue.neighborhood) {
            const neighborhood = venue.neighborhood.name;
            acc[neighborhood] = {
              zipCodes: [
                ...new Set([
                  ...acc[neighborhood]?.zipCodes || [],
                  venue.zip && venue.zip.trim(),
                ].filter(Boolean)),
              ],
              photoCount: (acc[neighborhood]?.photoCount || 0) + venue.photos.length,
            };
          }
          return acc;
        }, {});
        const chartData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderWidth: 0,
            },
          ],
        };
        let chartPhotoCountTotal = 0;
        const chartColors = [
          'rgba(12, 36, 97,1.0)',
          'rgba(229, 142, 38,1.0)',
          'rgba(7, 153, 146,1.0)',
          'rgba(30, 55, 153,1.0)',
          'rgba(229, 80, 57,1.0)',
          'rgba(60, 99, 130,1.0)',
          'rgba(120, 224, 143,1.0)',
          'rgba(183, 21, 64,1.0)',
          'rgba(74, 105, 189,1.0)',
          'rgba(250, 152, 58,1.0)',
          'rgba(106, 137, 204,1.0)',
          'rgba(250, 211, 144,1.0)',
          'rgba(10, 61, 98,1.0)',
          'rgba(235, 47, 6,1.0)',
          'rgba(246, 185, 59,1.0)',
          'rgba(96, 163, 188,1.0)',
          'rgba(248, 194, 145,1.0)',
          'rgba(56, 173, 169,1.0)',
          'rgba(184, 233, 148,1.0)',
          'rgba(130, 204, 221,1.0)',
        ];
        Object.keys(neighborhoods).forEach((name, i) => {
          const { photoCount, zipCodes } = neighborhoods[name];
          chartData.labels.push(`${name}: ${zipCodes.join(', ')}`);
          chartData.datasets[0].data.push(photoCount);
          chartPhotoCountTotal += photoCount;
          chartData.datasets[0].backgroundColor.push(chartColors[i]);
        });
        setReportSelectChartPhotoCountTotal(chartPhotoCountTotal);
        setReportSelectChartData(chartData);
      })();
    }

    return () => {
      mounted = false;
    };
  }, [reportSelectJobActive]);

  const generateReportPdf = async () => {
    setReportSelectPhotoExportCounter(null);
    const pdfDoc = await PDFDocument.load(await fetch(reportPdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();
    const pdfLastPage = pdfDoc.getPageCount() - 1;
    pdfDoc.registerFontkit(fontkit);
    const montserratBoldFontBytes = await fetch(montserratBoldFont).then((res) => res.arrayBuffer());
    const montserratSemiBoldFontBytes = await fetch(montserratSemiBoldFont).then((res) => res.arrayBuffer());
    const montserratRegularFontBytes = await fetch(montserratRegularFont).then((res) => res.arrayBuffer());

    // [
    //   { key: 'campaignTitle', value: detailData.title },
    // ].forEach(({ key, value }) => {
    //   const textField = pdfForm.getTextField(key);
    //     textField.setText(value);
    // });
    const textField = pdfForm.getTextField('chartTitle');
    textField.setText(`${detailData.title} - ${reportSelectJobActive?.campaignMarket.market.name} - Neighborhood, Zips`);
    textField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));
    pdfForm.getButton('chartImage').setImage(await pdfDoc.embedPng(reportSelectChartRef.current.toBase64Image('image/png')));
    const chartLegend = await html2canvas(reportSelectChartLegendRef.current, { backgroundColor: null });
    pdfForm.getButton('chartLegendImage').setImage(await pdfDoc.embedPng(chartLegend.toDataURL('image/png')));

    const reportPages = [];
    const reportPhotos = new JSZip();
    await Promise.all(chunk(reportSelectPhotosActive, 1).map(async (photos) => {
      const saved = await pdfDoc.saveAsBase64();
      const pdfDocPhotoPage = await PDFDocument.load(saved);
      const pdfFormPhotoPage = pdfDocPhotoPage.getForm();
      pdfDocPhotoPage.registerFontkit(fontkit);
      // pdfFormPhotoPage.getTextField('jobName').setText(`${reportSelectJobActive?.item.name} / ${reportSelectJobActive?.campaignMarket.market.name}`);

      await Promise.all(photos.map(async ({ photo, crop, scale, venue, position }, i) => {
        const photoData = await cropPhoto({ photo, crop, scale });

        await reportPhotos.file(`${detailData.title} - ${reportSelectJobActive?.campaignMarket.market.name} - ${position + 1}.jpg`, photoData, { base64: true });
        const photoField = pdfFormPhotoPage.getButton('photo');
        photoField.setImage(await pdfDocPhotoPage.embedJpg(photoData), ImageAlignment.Left);

        const campaignTitleField = pdfFormPhotoPage.getTextField('campaignTitle');
        campaignTitleField.setText(detailData.title);
        campaignTitleField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratBoldFontBytes, { subset: true }));

        const venueNameField = pdfFormPhotoPage.getTextField('venueName');
        venueNameField.setText(venue.name);
        venueNameField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));
        const venueTypeField = pdfFormPhotoPage.getTextField('venueType');
        venueTypeField.setText(venue.type?.type || '-');
        venueTypeField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueNeighborhoodField = pdfFormPhotoPage.getTextField('venueNeighborhood');
        venueNeighborhoodField.setText(venue.neighborhood?.name ? `${venue.neighborhood.name}` : '-');
        venueNeighborhoodField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueLocationField = pdfFormPhotoPage.getTextField('venueLocation');
        venueLocationField.setText(`${venue.city}, ${venue.state} ${venue.zip}`);
        venueLocationField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        setReportSelectPhotoExportCounter(position + 1);
      }));
      /* if (photos.length === 1) {
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getButton('photo2'));
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getTextField('location2'));
      } */

      const venue = photos[0].venue;
      const venueLocationRect = pdfFormPhotoPage.getTextField('venueLocation').acroField.getWidgets()[0].getRectangle();

      pdfFormPhotoPage.flatten();
      const [photoPage] = await pdfDoc.copyPages(pdfDocPhotoPage, [pdfLastPage]);

      const link = photoPage.doc.context.register(
        photoPage.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [ venueLocationRect.x, venueLocationRect.y - 20, venueLocationRect.x + venueLocationRect.width, venueLocationRect.y + venueLocationRect.height ],
          Border: [ 0, 0, 0 ],
          C: [ 0, 0, 0 ],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(venue.googlePlaceId ? `https://www.google.com/maps/place/?q=place_id:${venue.googlePlaceId}` : `https://maps.google.com/?q=${encodeURIComponent(`${venue.name},${venue.streetNumber} ${venue.streetName},${venue.city},${venue.state}`)}`),
          },
        }),
      );
      photoPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));

      reportPages.push(photoPage);
      // pdfDoc.addPage(copied);
    }));
    reportPages.forEach((page) => pdfDoc.addPage(page));

    pdfDoc.removePage(pdfLastPage);
    pdfForm.getFields().forEach((f) => f.enableReadOnly());
    pdfDoc.setTitle(`${detailData.title} Report`);
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    saveAs(pdfBlob, `${detailData.title} Report ${moment().format('M-D-YYYY')}.pdf`);

    const photosBlob = await reportPhotos.generateAsync({ type: 'blob' });
    saveAs(photosBlob, `${detailData.title} Report Photos ${moment().format('M-D-YYYY')}.zip`);
  };

  const exportIcon = reportSelectPhotosActive.length && !reportSelectPhotosActive.some(({ photo }) => !photo) && {
    type: iconType.EXPORT,
    onClick: async () => generateReportPdf(),
    tooltip: 'Export Campaign Report',
    exportingMsg: `Exporting${reportSelectPhotoExportCounter ? ` ${reportSelectPhotoExportCounter} of ${reportSelectPhotosActive.length} ` : ''}...`,
  };

  const changeReportSelectPhoto = (photo) => {
    if (isClient) {
      return;
    }
    setReportSelectPhotosActive((reportSelectPhotosActive) =>
      reportSelectPhotosActive.map((item) => item.id === photo.id ? photo : item));

    if (reportSelectPhotoModal) {
      axios.put(`${CampaignReportPhoto.apiPath}/${reportSelectPhotoModal.id}`, { ...reportSelectPhotoModal.crop, scale: reportSelectPhotoModal.scale });
    }
  };

  const onReportSelectRowsRendered = ({ startIndex, stopIndex }) => {
    Promise.all(
      range(startIndex, stopIndex + 1)
        .map((index) => reportSelectPhotos[index])
        .filter((photo) => !reportSelectPhotosActive.some((photoActive) => photoActive.id === photo.id) && photo.fk_jobId === reportSelectJobActive.id)
        .map((photo) => !reportSelectPhotoData[photo.id] && fetchPhoto({ photo })),
    );
  };
  const debounceReportSelectRowsRendered = useCallback(debounce(onReportSelectRowsRendered, 500), [ reportSelectPhotos, reportSelectPhotosActive, reportSelectPhotoData, reportSelectJobActive ]);
  const getReportSelectRowHeight = ({ index }) => !index ? 180 : !reportSelectPhotos[index].venuePhotoIndex ? 220 : 140;

  const initReportSelectShowing = (isShowing) => {
    const _reportSelectJobActive = reportSelectJobActive;
    setReportSelectJobActive(null);
    setIsReportSelectShowing(isShowing);
    setTimeout(() => {
      setReportSelectJobActive(_reportSelectJobActive);
      setIsReportSelectLoading(true);
      setReportSelectPhotos(null);
    });
  };

  /* Map */

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  const onMapLoad = useCallback(async (map) => {
    setReportMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setReportMap(null);
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      setReportMapInfoWindow(new google.maps.InfoWindow());
    }
  }, [isMapLoaded]);

  const setMapMarkerHover = ({ venue, marker, hover = true }) => {
    marker = marker || reportMapMarkers?.find((marker) => marker.venue.id === venue.id);
    if (hover) {
      reportMapInfoWindow.setContent(`
      <div class="infowindow">
        <h1>${venue.name}</h1>
        <div>${venue.streetNumber} ${venue.streetName}</div>
      </div>`);
      reportMapInfoWindow.open({
        map: reportMap,
        anchor: marker,
        shouldFocus: false,
      });
      // if (reportMap.getZoom() < 17) {
      //   reportMap.setZoom(17);
      // }
    } else {
      reportMapInfoWindow.close();
    }
  };

  const onMapRowMouseOver = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue });

  const onMapRowMouseOut = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue, hover: false });

  useEffect(async () => {
    if (reportMap) {
      const bounds = new google.maps.LatLngBounds();
      if (reportMapMarkers) {
        reportMapMarkers.forEach((marker) => {
          marker.setMap(null);
        });
      }
      if (reportSelectPhotosActive.length) {
        setReportMapMarkers(reportSelectPhotosActive.map(({ venue }) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(venue.latitude, venue.longitude),
            animation: google.maps.Animation.DROP,
            venue,
            map: reportMap,
          });
          marker.addListener('click', async () => {
            if (venue.googlePlaceId) {
              window.open(`https://www.google.com/maps/place/?q=place_id:${venue.googlePlaceId}`, '_blank');
            }
          });
          marker.addListener('mouseover', () => {
            setMapMarkerHover({ venue, marker });
          });
          marker.addListener('mouseout', () => {
            setMapMarkerHover({ venue, marker, hover: false });
          });

          bounds.extend(marker.position);
          return marker;
        }));
        reportMap.fitBounds(bounds);
      }
    }
  }, [ reportMap, reportSelectPhotosActive ]);

  return (
    <>
      {reportSelectJobs.length > 1 &&
        <Dropdown className="job">
          <Dropdown.Toggle variant="outline-secondary">{`${reportSelectJobActive?.item.name}${reportSelectJobActive?.campaignMarket ? ` / ${reportSelectJobActive.campaignMarket.market.name}` : ''}`}</Dropdown.Toggle>
          <Dropdown.Menu>
            {reportSelectJobs?.map((job, i) => (
              <React.Fragment key={i}>
                <Dropdown.Item
                  className={classNames({ active: job.id === reportSelectJobActive?.id })}
                  onClick={() => {
                    setReportSelectJobActive(job);
                    setIsReportSelectLoading(true);
                    setReportSelectPhotos(null);
                  }}
                >
                  {`${job.item.name}${job.campaignMarket ? ` / ${job.campaignMarket.market.name}` : ''}`}
                </Dropdown.Item>
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>}
      <Header
        label={`${detailData?.title} Report`}
        icons={[
          !isClient && {
            type: iconType.GEAR,
            detail: true,
            tooltip: 'Configure Campaign Report',
            onClick: () => initReportSelectShowing(true),
          },
          exportIcon,
        ].filter(Boolean)}
      />
      {isReportSelectLoading ? <Spinner /> :
       !reportSelectPhotosActive.length ? <Alert variant="secondary" className="empty">No data</Alert> :
        <div
          className="content"
          // ref={(el) => {
          //   if (el) {
          //     setReportNestedHeight(window.innerHeight - el.getBoundingClientRect().top - 30);
          //   }
          // }}
          // style={{ height: reportNestedHeight }}
        >
          <div className="cover slide">
            <img
              src={reportCoverImage}
            />
          </div>
          {isMapLoaded &&
            <div className="map slide">
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: 400,
                }}
                options={{
                  controlSize: 22,
                  gestureHandling: 'greedy',
                  styles: [
                    {
                      featureType: 'poi',
                      stylers: [
                        {
                          visibility: 'off',
                        },
                      ],
                    },
                  ],
                }}
                onLoad={onMapLoad}
                onUnmount={onMapUnmount}
              />
            </div>}
          {reportSelectChartData &&
          <div className="neighborhood slide">
            <div className="title">
              {detailData.title} - {reportSelectJobActive?.campaignMarket.market.name} - Neighborhood, Zips
            </div>
            <div>
              <div className="chart">
                <Doughnut
                  ref={reportSelectChartRef}
                  width={950}
                  height={500}
                  options={{
                    animation: {
                      duration: 0,
                    },
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                        // position: 'left',
                        // labels: {
                        //   boxWidth: 12,
                        //   font: {
                        //     size: 12,
                        //   },
                        // },
                      },
                      datalabels: {
                        color: '#ffffff',
                        formatter: (value) =>
                          `${((value / reportSelectChartPhotoCountTotal) * 100).toFixed(1)}%`,
                      },
                    },
                  }}
                  data={reportSelectChartData}
                />
              </div>
              <div className="chart-legend">
                <ul ref={reportSelectChartLegendRef}>
                  {reportSelectChartData.labels.map((label, i) => (
                    <li>
                      <div className="bullet" style={{ backgroundColor: reportSelectChartData.datasets[0]?.backgroundColor[i] }} />
                      <div>{label}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>}

          {!reportSelectPhotosActive.length ? <Alert variant="secondary" className="empty">No Venue Photos Available</Alert> :
            <SortableList
              onSortEnd={(oldIndex, newIndex) => {
                const sorted = arrayMove(reportSelectPhotosActive, oldIndex, newIndex);
                setReportSelectPhotosActive(sorted);
                axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportSelectJobActive.id, reposition: sorted.map(({ id }, i) => ({ fk_photoId: id, position: i })) });
              }}
              className="photos"
              // draggedItemClassName="campaign report-select photo drag"
            >
              {reportSelectPhotosActive.map((photoData, i) =>
                <SortableItem key={photoData.id}>
                  <div className={classNames('photo slide', { loading: !photoData.photo })}>
                    <div className="col">
                      <div>
                        <div className="label">
                          Venue:
                        </div>
                        {photoData.venue.name}
                      </div>
                      <div>
                        <div className="label">
                          Type:
                        </div>
                        {photoData.venue.type?.type || '-'}
                      </div>
                      <div>
                        <div className="label">
                          Neighborhood:
                        </div>
                        {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                      </div>
                      <div className="location">
                        <div className="label">
                          Location:
                        </div>
                        <a href={photoData.venue.googlePlaceId ? `https://www.google.com/maps/place/?q=place_id:${photoData.venue.googlePlaceId}` : `https://maps.google.com/?q=${encodeURIComponent(`${photoData.venue.name},${photoData.venue.streetNumber} ${photoData.venue.streetName},${photoData.venue.city},${photoData.venue.state}`)}`} target="_blank" rel="noreferrer">
                          {photoData.venue.city}, {photoData.venue.state} {photoData.venue.zip}
                          <div>
                            <img
                              src={reportMapMarker}
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="title">
                        {detailData.title}
                      </div>
                      <div className="selected">
                        {!photoData.photo ?
                          <>
                            <FontAwesomeIcon icon={faImage} />
                            <FontAwesomeIcon icon={faSquare} />
                            <Spinner />
                          </> :
                          <div>
                            {photoData.crop?.photo && <img // eslint-disable-line
                              src={`data:image/jpg;base64,${photoData.crop.photo}`}
                              draggable={false}
                            />}
                          </div>}
                      </div>
                    </div>
                  </div>
                </SortableItem>)}
            </SortableList>}
        </div>}
      {isReportSelectShowing &&
      <>
        <Modal
          showing={isReportSelectShowing}
          onHide={() => initReportSelectShowing(false)}
          fullScreen
          className={classNames('campaign report-select', { client: isClient })}
          body={
            <>
              {reportSelectJobs.length > 1 &&
                <Dropdown className="job">
                  <Dropdown.Toggle variant="outline-secondary">{`${reportSelectJobActive?.item.name}${reportSelectJobActive?.campaignMarket ? ` / ${reportSelectJobActive.campaignMarket.market.name}` : ''}`}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {reportSelectJobs?.map((job, i) => (
                      <React.Fragment key={i}>
                        <Dropdown.Item
                          className={classNames({ active: job.id === reportSelectJobActive?.id })}
                          onClick={() => {
                            setReportSelectJobActive(job);
                            setReportSelectPhotos(null);
                          }}
                        >
                          {`${job.item.name}${job.campaignMarket ? ` / ${job.campaignMarket.market.name}` : ''}`}
                        </Dropdown.Item>
                      </React.Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>}
              <Row>
                <Col
                  className="col"
                  md={!reportSelectPhotos?.length ? 12 : 3}
                  style={{ maxHeight: window.innerHeight - 100 }}
                  ref={reportSelectPhotosRef}
                >
                  {!reportSelectPhotos ? <Spinner /> :
                    (!reportSelectPhotos.length ? <Alert variant="secondary" className="empty">No photos</Alert> :
                        <WindowScroller scrollElement={reportSelectPhotosRef.current}>
                          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                            <AutoSizer disableHeight>
                              {({ width }) => (
                                <div
                                  ref={registerChild}
                                  style={{
                                    width: '100%',
                                    height: `${
                                      reportSelectPhotos
                                        .map((_, index) => getReportSelectRowHeight({ index }))
                                        .reduce((acc, h) => acc + h, 0)
                                    }px`,
                                  }}
                                >
                                  <List
                                    style={{
                                      height: '100%',
                                    }}
                                    width={width - 5}
                                    height={height}
                                    autoHeight
                                    overscanRowCount={10}
                                    scrollTop={scrollTop}
                                    isScrolling={isScrolling}
                                    onScroll={onChildScroll}
                                    rowCount={reportSelectPhotos.length}
                                    rowHeight={getReportSelectRowHeight}
                                    rowRenderer={({ index, key, style }) => {
                                      const { id, venuePhotoIndex, venue, date, time } = reportSelectPhotos[index];
                                      const photoData = reportSelectPhotoData[id];
                                      const isSelected = reportSelectPhotosActive.some(({ id }) => photoData?.id === id);
                                      return (
                                        <div
                                          className={classNames('photo', { loading: !photoData })}
                                          key={key}
                                          style={style}
                                        >
                                          {!venuePhotoIndex &&
                                            <div className="venue">
                                              {venue.name}
                                              <div>{`${venue.neighborhood?.name ? `${venue.neighborhood.name}, ` : ''}${venue.market?.name}`}</div>
                                            </div>}
                                          <div>
                                            {!photoData ?
                                              <>
                                                <FontAwesomeIcon icon={faImage} />
                                                <Spinner />
                                              </> :
                                              <div className={classNames({ selected: isSelected })}>
                                                <i
                                                  className="select"
                                                  onClick={() => {
                                                    if (isClient) {
                                                      return;
                                                    }
                                                    setReportSelectPhotosActive(!isSelected ? [
                                                      ...reportSelectPhotosActive,
                                                      photoData,
                                                    ] : [
                                                      ...reportSelectPhotosActive.filter(({ id }) => photoData.id !== id),
                                                    ]);
                                                    if (!isSelected) {
                                                      axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id });
                                                    } else {
                                                      axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id } });
                                                    }
                                                  }}
                                                />
                                                <img // eslint-disable-line
                                                  src={`data:image/jpg;base64,${photoData.photo}`}
                                                  onClick={() => setReportSelectPhotoModal({
                                                    ...photoData,
                                                    ...reportSelectPhotosActive.find(({ id }) => photoData.id === id),
                                                    selected: isSelected,
                                                  })}
                                                />
                                                {date ? <div className="date">{`${moment(date).format('M/D/YYYY')} ${time ? moment(time, 'HH:mm:ss').format('h:mm a') : ''}`}</div> : null}
                                              </div>}
                                          </div>
                                        </div>
                                      );
                                    }}
                                    onRowsRendered={({ startIndex, stopIndex }) => {
                                      debounceReportSelectRowsRendered({ startIndex, stopIndex });
                                    }}
                                  />
                                </div>
                              )}
                            </AutoSizer>
                          )}
                        </WindowScroller>
                    )}
                </Col>
                {reportSelectPhotos?.length ?
                  <Col className="col" md={9}>
                    <div ref={reportSelectPhotosActiveRef}>
                      <Header
                        label={`${detailData?.title} Report`}
                        icons={[
                          exportIcon,
                        ].filter(Boolean)}
                      />
                      {!reportSelectPhotosActive.length ? <Alert variant="secondary" className="empty">None selected</Alert> :
                        <div className="photos-container" style={{ maxHeight: window.innerHeight - 160 }}>
                          <SortableList
                            onSortEnd={(oldIndex, newIndex) => {
                              const sorted = arrayMove(reportSelectPhotosActive, oldIndex, newIndex);
                              setReportSelectPhotosActive(sorted);
                              axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportSelectJobActive.id, reposition: sorted.map(({ id }, i) => ({ fk_photoId: id, position: i })) });
                            }}
                            className="photos"
                            draggedItemClassName="campaign report-select photo drag"
                          >
                            {reportSelectPhotosActive.map((photoData, i) =>
                              <SortableItem key={photoData.id}>
                                <div className={classNames('photo', { loading: !photoData.photo })}>
                                  <div className="selected">
                                    {!photoData.photo ?
                                      <>
                                        <FontAwesomeIcon icon={faImage} />
                                        <FontAwesomeIcon icon={faSquare} />
                                        <Spinner />
                                      </> :
                                      <>
                                        <i
                                          className="select"
                                          onClick={() => {
                                            if (isClient) {
                                              return;
                                            }
                                            setReportSelectPhotosActive([
                                              ...reportSelectPhotosActive.filter(({ id }) => photoData.id !== id),
                                            ]);
                                            axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportSelectJobActive.id, photoId: photoData.id } });
                                          }}
                                        />
                                        <div
                                          onClick={() =>
                                            setReportSelectPhotoModal({
                                              ...photoData,
                                              selected: true,
                                            })}
                                        >
                                          {photoData.crop && <div className="crop-selection" style={{ top: `${photoData.crop.y}%`, left: `${photoData.crop.x}%`, width: `${photoData.crop.width}%`, height: `${photoData.crop.height}%` }} />}
                                          <img // eslint-disable-line
                                            src={`data:image/jpg;base64,${photoData.photo}`}
                                            draggable={false}
                                            style={{ transform: `scale(${photoData.scale})` }}
                                            onLoad={(e) => {
                                              if (!photoData.crop) {
                                                photoData.crop = calcReportSelectPhotoCrop(e.currentTarget);
                                                photoData.scale = 1;
                                                changeReportSelectPhoto(photoData);
                                              }
                                            }}
                                            onClick={() =>
                                              setReportSelectPhotoModal({
                                                ...photoData,
                                                selected: true,
                                              })}
                                          />
                                        </div>
                                      </>}
                                  </div>
                                  <div className="caption">
                                    {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                                  </div>
                                </div>
                              </SortableItem>)}
                          </SortableList>
                        </div>}
                    </div>
                  </Col> : null}
              </Row>
            </>
          }
        />
        <Modal
          showing={!!reportSelectPhotoModal}
          onHide={() => {
            changeReportSelectPhoto(reportSelectPhotoModal);
            setReportSelectPhotoModal(null);
          }}
          fullScreen
          className="campaign report-select image"
          body={
            reportSelectPhotoModal?.selected ?
              <>
                <ReactCrop
                  crop={{ ...reportSelectPhotoModal.crop, unit: '%' }}
                  onChange={(_, percentCrop) => setReportSelectPhotoModal({ ...reportSelectPhotoModal, crop: percentCrop })}
                  keepSelection
                  aspect={16 / 9}
                  disabled={isClient}
                >
                  <img
                    ref={reportSelectPhotoRef}
                    src={`data:image/jpg;base64,${reportSelectPhotoModal.photo}`}
                    style={{ transform: `scale(${reportSelectPhotoModal.scale})` }}
                  />
                </ReactCrop>
                <div
                  className="controls"
                  style={reportSelectPhotoModal.crop && {
                    top: `${reportSelectPhotoModal.crop.y + reportSelectPhotoModal.crop.height}%`,
                    left: `${reportSelectPhotoModal.crop.x + reportSelectPhotoModal.crop.width}%`,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassPlus}
                    onClick={() => setReportSelectPhotoModal({ ...reportSelectPhotoModal, scale: reportSelectPhotoModal.scale + 0.1 })}
                  />
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassMinus}
                    onClick={() => setReportSelectPhotoModal({ ...reportSelectPhotoModal, scale: Math.max(reportSelectPhotoModal.scale - 0.1, 1) })}
                    className={classNames({ disabled: reportSelectPhotoModal.scale === 1 })}
                  />
                </div>
              </> :
              <img src={`data:image/jpg;base64,${reportSelectPhotoModal?.photo}`} />
          }
        />
      </>}
    </>
  );
};


const CampaignDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const isClient = _isClient();

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  const [ campaignFormState, setCampaignFormState ] = useState(null);
  // const [ reportSelectDetailData, setReportSelectDetailData ] = useState(null);
  // const [ isReportSelectShowing, setIsReportSelectShowing ] = useState(false);



  const schemaCampaignContact = (type) => {
    const typeKey = (key) => type === 'billing' ? `${type}${capitalize(key)}` : key;
    const fields = cloneDeep(pick(schema, [
      ...[ `fk_${type}ContactId`, 'id' ],
      ...[ 'email', 'phone', 'mobile', 'fax', 'address', 'city', 'state', 'zip' ].map((key) => typeKey(key)),
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 ? {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data: contact } = await axios.get(`${Contact.apiPath}/${item.value}`);
                [ 'email', 'phone', 'mobile', 'fax' ].forEach((key) => setFieldValue(typeKey(key), contact[key]));
              }
            },
          } : {
            readOnly: true,
          },
        },
      },
    }), {});
  };

  return (
    <div className="detail campaign">
      <Form
        apiPath={apiPath}
        schema={modelParent === Client.model ? alterSchema(schema, { formFieldsHide: ['fk_clientId'] }) :
                isClient ? alterSchema(schema, { formFieldsInfo: [ 'title', 'type', 'status', 'dateStart', 'dateEnd' ], formFieldsHide: [ 'routeSheetTotal', 'routeSheetActive', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'dateInitialRoute', 'venuesAudit', 'createdBy', 'dateCreation', 'modifiedBy', 'dateMod' ], formFieldsInfoOnly: true }) : schema}
        data={data}
        readOnly={isClient}
        dataDefault={!data && (
          modelParent === Client.model ? {
            fk_clientId: dataParent.id,
          } :
          modelParent === Contact.model ? {
            fk_primaryContactId: dataParent.id,
            email: dataParent.email,
            phone: dataParent.phone,
            mobile: dataParent.mobile,
            fax: dataParent.fax,
            /* address: dataParent.address,
            city: dataParent.city,
            state: dataParent.state,
            zip: dataParent.zip, */
            fk_clientId: dataParent.fk_clientId,
            } :
          null
        )}
        onSubmit={onSubmit}
        getState={(state) => setCampaignFormState(state)}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'contact',
            title: 'Contacts',
            content:
              <div>
                <Row>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('primary')}
                      data={data}
                    />
                  </Col>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('billing')}
                      data={data}
                    />
                  </Col>
                </Row>
              </div>,
          },
          {
            key: 'item',
            title: 'Items',
            content:
              <Crud
                apiPath={CampaignItem.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignItem.schema, { gridCols: [ 'item.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart', 'dateInitialPosting', 'dateEnd' ] })}
                schemaParent={schema}
                routePath={CampaignItem.routePath}
                label={CampaignItem.label}
                nested
                dataParent={data}
                model={CampaignItem.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'market',
            title: 'Markets',
            content:
              <Crud
                apiPath={CampaignMarket.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignMarket.schema, { gridCols: [ 'market.name', 'neighborhoods' ] })}
                schemaParent={schema}
                routePath={CampaignMarket.routePath}
                label={CampaignMarket.label}
                nested
                showDelete
                dataParent={data}
                model={CampaignMarket.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'job',
            title: 'Jobs',
            content:
              <Crud
                apiPath={Job.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Job.schema, { gridCols: [ 'id', 'item.name', 'campaignMarket.market.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart' ] })}
                schemaParent={schema}
                routePath={Job.routePath}
                label={Job.label}
                nested
                dataParent={data}
                model={Job.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'sheet',
            title: 'Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ distributionRoute: true, campaignId: id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'fk_routeId', 'route.neighborhood.name', 'rep.fullName', 'photosProcessedBy', 'status', 'type', 'distributionAudit' ] })}
                schemaParent={schema}
                routePath={RouteSheet.routePath}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'plan',
            title: 'Plan',
            content:
              <Tabs
                tabs={[
                  {
                    key: 'venues',
                    title: 'Venues',
                    content:
                      <Venue
                        dataParent={data}
                        modelParent={model}
                      />,
                  },
                  {
                    key: 'rates',
                    title: 'Rates',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.RATE}
                      />,
                  },
                  {
                    key: 'deck',
                    title: 'Deck',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.DECK}
                      />,
                  },
                ]}
              />,
          },
          {
            key: 'agreement',
            title: 'Agreement',
            content:
              <Agreement
                data={data}
                schema={schema}
                apiPath={apiPath}
                campaignFormState={campaignFormState}
              />,
          },
          {
            key: 'photo',
            title: 'Photos',
            content:
              <Photo
                dataParent={data}
                modelParent={model}
              />,
          },
          {
            key: 'venue',
            title: 'Venues',
            content:
              <Venue
                dataParent={data}
                modelParent={model}
                apiParams={{ distributionVenue: true }}
                showAdd={false}
                showMap={false}
              />,
          },
          {
            key: 'attachments',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
          {
            key: 'flow',
            title: 'Flow',
            content:
              <Crud
                apiPath={CampaignStage.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignStage.schema, {
                    gridColsShow: [ 'flow.notes', 'flow.completedBy.fullName', 'flow.completedDate' ],
                    gridColsHide: [ 'status', 'number', 'position' ],
                    formFieldsShow: ['flow.notes'],
                    formFieldsHide: [ 'status', 'number', 'position' ],
                    formFieldsInfo: [ 'stage', 'team' ],
                  })}
                schemaParent={schema}
                routePath={CampaignStage.routePath}
                label={CampaignStage.label}
                nested
                dataParent={data}
                model={CampaignStage.model}
                modelParent={model}
                checkSelect
                showAdd={false}
                checkSelectedField="flow.completedDate"
                onCheckSelected={(node) => CampaignStageFlow.onSelected({ node, dataParent: data })}
                // exportData: (rowData) => rowData?.filter((item) => item.campaignPlanVenue),
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'report',
            title: 'Report',
            clientAccess: true,
            content:
              <Report
                detailData={data}
              />,
          },
        ].filter((tab) => !isClient || tab.clientAccess)}
      />}
    </div>
  );
};

export default CampaignDetail;
